import { BarChart, Bar, ResponsiveContainer } from 'recharts';
import { RoundedTopBarFill } from '../../Card/rounded-topbar';
import MetricCard from '../../Card/metric-card';
import { DataContext } from '../../../context/DataContext';
import { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import './theme.css';

const TransactionHistoryContainer = ({ chartDetails, title, transactions }) => {

    const { setModalData, setShow } = useContext(DataContext);

    return (
        <MetricCard
            key={1}
            title={
                <Nav.Link
                    className="text-info p-0 ms-1"
                    onClick={() => {
                        const [key] = transactions[0].keys.filter((key) => (key.startsWith('soil-field-plot-')));
                        setModalData({ title: `Transaction history ${key ? `for ${key}` : ''}`, type: "history", data: transactions });
                        setShow(true);
                    }}
                >
                    {`${title} History`}
                </Nav.Link>
            }
            rounded="lg"
            metricClassName="text-2xl mt-1"
            info={<><br/></>}
            chart={
                <>
                    <div
                        style={{ color: chartDetails.fill }}
                        className="mb-3 text-sm font-medium"
                    >
                    </div>
                    <div className="h-12 w-20 @[16.25rem]:h-16 @[16.25rem]:w-24 @xs:h-20 @xs:w-28">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart barSize={6} barGap={5} data={chartDetails.chart}>
                                <Bar
                                    dataKey="sale"
                                    fill={chartDetails.fill}
                                    shape={<RoundedTopBarFill cornerRadius={2} />}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </>
            }
            chartClassName="flex flex-col w-auto h-auto text-center"
            className="@container @7xl:text-[15px] [&>div]:items-end"
        />
    )

}

export default TransactionHistoryContainer;