import React, { useContext, useState } from 'react';
import Map, { Layer, Source, ScaleControl, NavigationControl, FullscreenControl } from 'react-map-gl';
import { GeneralContext } from '../../context/GeneralContext';

import './mapgl.css';
import './theme.css';

const MapView = ({ children, mapElements: { areas }, mapType, viewport, setViewport }) => {

    const { env } = useContext(GeneralContext);
    const [areaConfiguration,] = useState({ id: 'fields', type: 'fill', paint: { 'fill-color': "#000000", 'fill-opacity': 0.5 } });


    return (
        <>
            <Map
                initialViewState={viewport}
                pitch={60}
                maxZoom={24}
                minZoom={8}
                scrollZoom={true}
                mapboxAccessToken={env.maps}
                mapStyle={mapType}
            >
                {children}
                <ScaleControl />
                <NavigationControl />
                <FullscreenControl />
                <Source type="geojson" data={areas}>
                    <Layer {...areaConfiguration} />
                </Source>
            </Map>
        </>
    );
}

export default MapView