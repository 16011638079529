import React, { useContext, useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from 'react-bootstrap';
import secureLocalStorage from "react-secure-storage";

import CustomModal from './../Modal/index';
import { GeneralContext } from './../../context/GeneralContext';
import { DataContext } from './../../context/DataContext';
import Navigation from './../Navigation/index';
import RoleRoute from './../RoleRoute/index';

import Unauthorized from './../../pages/Unauthorized/index';
import Default from './../../pages/Default/index';
import Dashboard from './../../pages/Dashboard/index';
import Plot from './../../pages/Plot/index';
import PlotDashboard from './../../pages/PlotDashboard/index';
import SinglePlotDashboard from './../../pages/SinglePlotDashboard/index';
import Well from './../../pages/Well/index';
import FieldDashboard from './../../pages/FieldsDashboard/index';
import SingleFieldDashboard from './../../pages/SingleFieldDashboard/index';
import UserDashboard from "../../pages/UserDashboard";
import NFT from './../../pages/NFT/index';
import Login from './../../pages/Login/index';
import Cookies from 'js-cookie';


import Header from "../Header";

import './theme.css';

const {
  REACT_APP_STREAMS: envStreams,
  REACT_APP_EXTRA_ROUTES: envExtraRoutes,
  REACT_APP_STAGES: envStages,
  REACT_APP_MAP_LOCATION_LATITUDE: mapDefaultLatitude,
  REACT_APP_MAP_LOCATION_LONGITUDE: mapDefaultLongitude,
  REACT_APP_MAP_STYLE: mapDefaultStyle,
  REACT_APP_REST_PROTOCOL
} = process.env

const Core = () => {

  const { axios } = useContext(GeneralContext);

  const [show, setShow] = useState(false);
  const [stream, setStream] = useState('');
  const [id, setId] = useState('');
  const [stages,] = useState(envStages.split(','));
  const [routes, setRoutes] = useState([]);
  const [extraRoutes, setExtraRoutes] = useState([]);
  const [modalData, setModalData] = useState({ type: '', data: [] });

  const [user, setUser] = useState(secureLocalStorage.getItem("auth")?.user);
  const [role, setRole] = useState(secureLocalStorage.getItem("auth")?.role);
  const [userAssets, setUserAssets] = useState([]);
  const [systemUsers, setSystemUsers] = useState([]);
  const [totalTokens, setTotalTokens] = useState(0);
  const [activeRoute, setActiveRoute] = useState("/");

  const handleLogout = async () => {
    secureLocalStorage.setItem("auth", { user: null, role: null });
    await Cookies.remove('access_token');
    setRole(null);
    setUser(null);
  }

  const handleLogin = async (data) => {
    const { role, ...user } = data;
    secureLocalStorage.setItem("auth", { user, role });
    await Cookies.set('access_token', user.token, { expires: 1, secure: REACT_APP_REST_PROTOCOL === "https" });
    setUser(user);
    setRole(role);
  }

  const handleClose = () => {
    setModalData({ type: '', data: [] })
    setShow(false)
  };

  const getTransactionsByKey = async (stream, key, type) => {
    try {
      setStream(stream)
      let route = stream;
      switch (type) {
        case 'file':
          route = `${stream}-document-metadata`;
          break;
        case 'maptrace':
        case 'location':
          route = 'location';
          break;
        default:
          break;
      }

      const { error, data: { data } } = await axios.get(`/${route}/transactions-by-key/${key}?page=1&items=1000`);
      if (!error) {
        setId(key)
        setShow(true);
        setModalData({ type, data });
      }
    } catch (err) {
      setModalData({ type: '', data: [] });
    }
  };

  const getNavigationItems = useCallback(async () => {
    if (envStreams) {
      setRoutes(envStreams.split(','));
    } else {
      const { data: { error, data: { streams } } } = await axios.get(`/status`);
      if (!error) {
        setRoutes(streams);
      }
    }
    if (envExtraRoutes) {
      setExtraRoutes(envExtraRoutes.split(','))
    }

  }, [axios]);

  useEffect(() => {
    getNavigationItems();
  }, [getNavigationItems]);

  const getUserAssets = async () => {
    try {
      const { address } = user;
      const { error, data: { data } } = await axios.get(`/balances?addresses=${address}`);
      if (!error && data.balances) {
        const { balances: [, totalContainer] } = data;
        const [total, list] = totalContainer;
        setTotalTokens(list.reduce((acc, { qty }) => (acc + qty), 0));
        setUserAssets(list);
      }
    } catch (err) {
    }
  }

  const getUsersList = async () => {
    try {
      const { error, data: { data } } = await axios.get(`/users`);
      if (!error) {
        setSystemUsers(prev => (
          data
            .filter(e => (e.address !== user.address)))
        )
      }
    } catch (err) {
    }
  }

  useEffect(() => {
    if (user) {
      getUsersList().catch(console.error);
      getUserAssets().catch(console.error);
    }
  }, [user])

  useEffect(() => { }, [userAssets])

  return (
    <main className="flex min-h-screen flex-grow">
      <div className="flex w-full flex-col ">
        <DataContext.Provider value={{
          userAssets,
          getUserAssets,
          systemUsers,
          totalTokens,
          routes,
          mapDefaultStyle,
          mapDefaultLatitude,
          mapDefaultLongitude,
          role,
          user,
          activeRoute,
          setActiveRoute,
          handleLogin,
          handleLogout,
          setModalData,
          handleClose,
          setShow
        }}>
          <BrowserRouter>
            <Header routes={[...routes, ...extraRoutes]} />
            <CustomModal show={show} handleClose={handleClose} modalData={modalData} stages={stages} stream={stream} id={id} />
            <div className="flex flex-grow flex-col">
              <Routes>
                <Route exact path={`/plot`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <Plot getTransactionsByKey={getTransactionsByKey} stream={'plot'} />
                  </RoleRoute>} />
                <Route exact path={`/plots-data`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <PlotDashboard getTransactionsByKey={getTransactionsByKey} stream={'plot'} />
                  </RoleRoute>} />
                <Route exact path={`/plot-data`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <SinglePlotDashboard getTransactionsByKey={getTransactionsByKey} stream={'plot'} />
                  </RoleRoute>} />
                <Route exact path={`/field`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <Well getTransactionsByKey={getTransactionsByKey} stream={'well'} />
                  </RoleRoute>} />
                <Route exact path={`/fields-data`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <FieldDashboard getTransactionsByKey={getTransactionsByKey} stream={'well'} />
                  </RoleRoute>} />
                <Route exact path={`/field-data`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <SingleFieldDashboard getTransactionsByKey={getTransactionsByKey} stream={'field'} />
                  </RoleRoute>} />
                <Route exact path={`/user`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <UserDashboard getTransactionsByKey={() => { }} stream={'user'} />
                  </RoleRoute>} />
                <Route exact path={`/nfts`} element={
                  <RoleRoute role={role} roles={['admin']} >
                    <NFT stream='nfts' />
                  </RoleRoute>
                } />
                <Route exact path={`/`} element={
                  <RoleRoute role={role} roles={["admin", "user"]} >
                    <Dashboard getTransactionsByKey={() => { }} stream={''} />
                  </RoleRoute>} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/login" element={<Login />} />
              </Routes>
            </div>
          </BrowserRouter>
        </DataContext.Provider >
      </div>
    </main>
  );
};

export default Core;