import { useEffect, useState, useContext } from 'react';
import moment from "moment";
import { ArrowReturnRight, ArrowReturnLeft, Shift, XSquare } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import './theme.css';
import { Modal, Button, Nav } from 'react-bootstrap';

import DocIcon from './../Shared/icons/doc-solid';
import ImageIcon from './../Shared/icons/image-solid';
import XMLIcon from './../Shared/icons/xml-solid';
import PDFIcon from './../Shared/icons/pdf-solid';

import {defaultEventList, formatBytes, handleFileRender, handleIcons, titleCase} from "../../utils";
import { GeneralContext } from "../../context/GeneralContext";

const HistoryStory = ({ isField, events: eventsData, data, fieldId, documents: documentsData }) => {

    const { axios } = useContext(GeneralContext);
    const [quickFileContentDetails, setQuickFileContentDetails] = useState({ extension: '', data: '' });
    const [error, setError] = useState(false);

    const [events, setEvents] = useState(defaultEventList)

    useEffect(() => {
    }, []);

    const TransactionDetails = ({ data }) => renderData(data);

    const renderData = (data) => {
        // List of keys that should be rendered in uppercase
        const uppercaseKeys = [
            'Id'
        ];

        const ignoredKeys = [
            "area"
        ];

        return Object.keys(data).map(key => {

            const value = data[key];
            let formattedKey = key.split('_').join(" ");
            formattedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
            formattedKey = uppercaseKeys.includes(formattedKey) ? formattedKey.toUpperCase() : formattedKey;

            if (Array.isArray(value)) {
                // If the value is a flat array, join its elements with commas
                return (
                    ignoredKeys.includes(key)? <></> :
                    <li key={key}>
                        <strong className='ps-2'>
                            {formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)}
                        </strong>: {
                            ['date', 'timestamp'].includes(key)
                                ? moment(data[key]).format('YYYY-MM-DD hh:mm:ss')
                                : ['coordinates'].includes(key)
                                    ? data[key].map((c, i) => {
                                        return <li><strong className='ps-2'>{!!i ? 'Longitude' : 'Latitude'} :</strong> {c}</li>
                                    })
                                    : (value[0] && typeof value[0] === 'object') ? <div>
                                        <input type="checkbox" id={`toggle${formattedKey}`} style={{display: "none"}}/>
                                        <label htmlFor={`toggle${formattedKey}`}
                                               className="toggle-button btn btn-sm btn-primary w-32">Show/Hide Table</label>
                                        <div id={`content${formattedKey}`} className="collapsible-content">
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                    <tr>
                                                        {Object.keys(value[0]).map(key => (<th>{titleCase(key)}</th>))}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {value.map((item) => {
                                                            return <tr>
                                                                {Object.values(item).map(v => (
                                                                    <td>{v === true ? "✔" : (v === false ? "✗" : v)}</td>))}
                                                            </tr>
                                                        }
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div> : value.join(', ')
                    }
                    </li>
                );
            } else if (typeof value === 'object' && value !== null) {
                // If the value is an object (including nested objects), recursively render its contents
                return (
                    ignoredKeys.includes(key)? <></> :
                    <li key={key}>
                        <strong className='ps-2'>
                            {formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)}
                        </strong>:
                        <ul>
                            {renderData(value)}
                        </ul>
                    </li>
                );
            } else {
                // If the value is neither an array nor an object, render it as usual
                return (
                    ignoredKeys.includes(key)? <></> :
                    <li key={key}>
                        <strong className='ps-2'>
                            {formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1)}
                        </strong> : {value}
                    </li>
                );
            }
        });
    }

    const handleFileContent = async (stream, extension, mimetype, id, filename, txid) => {
        try {
            const { data: { error, data: content } } = await axios.get(`/document/${stream}/${id}/${filename}`);
            if (!error) {
                setQuickFileContentDetails({
                    txId: txid,
                    extension,
                    data: extension === 'json' ? JSON.parse(Buffer.from(content, "base64").toString()) : `data:${mimetype};base64,${content}`
                });
            }
        } catch (err) {
            setQuickFileContentDetails({ extension: '', data: '' });
            setError(true);
        }
    }

    const DocumentsDetails = ({ documents }) => {
        return documents ? documents.map((key, index) => {
            const document = documentsData[key];
            document.image = handleIcons(document.data.extension);
            if (!document) return <></>;
            return (
                <li>
                    <div className={`d-flex align-items-start`}>
                        <strong className='ps-2'>Document: </strong>
                        <Nav.Link
                            key={'hash'}
                            className="d-flex text-info p-0 ml-1"
                            onClick={async (e) => {
                                const { source, txid, data: { name, id, extension, mimetype, hash } } = document;
                                await handleFileContent(source, extension, mimetype, id, name, txid)
                            }}>
                            <div className="w-4 mr-1">
                                <document.image />
                            </div>
                            <span>{document.data.document_id}</span>
                        </Nav.Link>
                    </div>
                    <ol>
                        <li><strong>Name:</strong> {document.data.name}</li>
                        <li><strong>Hash:</strong> {document.data.hash}</li>
                        <li><strong>Size:</strong> {formatBytes(document.data.size, 2)}</li>
                    </ol>
                </li>
            );
        }) : [];

    }

    const EvenRowEmpty = ({ event, className }) => {
        return (
            <div className="row no-gutters">
                <div className="col-sm py-2">
                    <div className={`card border-${className}`}>
                        <div className="card-body">
                            <div><strong className={`text-${className}`}>{event.toUpperCase()} </strong></div>
                            <hr />
                            <div className="float-right small">
                                {/* <strong>Registered in Blockchain at : </strong>{moment(event_date).format('YYYY-MM-DD hh:mm:ss')} */}
                            </div>
                        </div>
                        <div className="card-body pt-4 text-center">
                            <strong className={`d-flex align-content-center align-items-center justify-content-center text-${className}`}>
                                <span className={`badge badge-pill badge-pill-text bg-light border border-${className}`}>
                                    <img src={`./../icons/noun-question-mark.svg`} className={`text-${className}`} size={10} />
                                </span>
                                <div> Waiting for event confirmation</div>
                            </strong>
                        </div>
                        <div className="card-body">
                            {/* <div className="text-left small"><strong>Tx ID : </strong>{txid}</div> */}
                        </div>
                    </div>
                </div>
                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className={`col border-right border-${className}`}>&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span className={`badge badge-pill bg-light border border-${className}`}>
                            <img src={`./../icons/${event.split(' ').join('_')}.svg`} className={`text-${className}`} size={18} />
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className={`col border-right border-${className}`}>&nbsp;</div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="col-sm col-sm-timeline"> </div>
            </div>
        )
    }

    const OddRowEmpty = ({ event, className }) => {
        return (
            <div className="row no-gutters">
                <div className="col-sm col-sm-timeline"> </div>
                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className={`col border-right border-${className}`}>&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span className={`badge badge-pill bg-light border border-${className}`}>
                            <img src={`./../icons/${event.split(' ').join('_')}.svg`} className={`text-${className}`} size={18} />
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className={`col border-right border${className}`}>&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col-sm py-2">
                    <div className={`card border-${className}`}>
                        <div className="card-body">
                            <div><strong className={`text-${className}`}>{event.toUpperCase()} </strong> </div>
                            <hr />
                            <div className="float-right small">
                                {/* <strong>Registered in Blockchain at : </strong>{moment(event_date).format('YYYY-MM-DD hh:mm:ss')} */}
                            </div>
                        </div>
                        <div className="card-body pt-4 text-center">
                            <strong className={`d-flex align-content-center align-items-center justify-content-center text-${className}`}>
                                <span className={`badge badge-pill badge-pill-text bg-light border border-${className}`}>
                                    <img src={`./../icons/noun-question-mark.svg`} className={`text-${className}`} size={10} />
                                </span>
                                <div> Waiting for event confirmation</div>
                            </strong>
                        </div>
                        <div className="card-body">
                            {/* <div className="text-center small"><strong>Tx ID : </strong>{txid}</div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const OddRowWithData = ({ documents, event, details, event_date, txid, className }) => {
        return (
            <div className="row no-gutters">
                <div className="col-sm col-sm-timeline"> </div>
                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className={`col border-right border-${className}`}>&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span className={`badge badge-pill bg-light border border-${className}`}>
                            <img src={`./../icons/${event.split(' ').join('_')}.svg`} className={`text-${className}`} size={18} />
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className={`col border-right border-${className}`}>&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                </div>
                <div className="col-sm py-2">
                    <div className={`card border-${className}`}>
                        <div className="card-body">
                            <div><strong className={`text-${className}`}>{event.toUpperCase()} </strong></div>
                            <hr />
                            <div className="float-right small m-2">
                                <strong>Registered in Blockchain at : </strong>{moment(event_date).format('YYYY-MM-DD hh:mm:ss')}
                            </div>
                        </div>
                        <ul className="card-body small ps-4">
                            <TransactionDetails data={details} />
                            <DocumentsDetails documents={documents} />
                        </ul>
                        <div className="card-body">
                            <div className="text-center small"><strong>Tx ID : </strong>{txid}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const EvenRowWithData = ({ documents, event, details, event_date, txid, className }) => {
        return (
            <div className="row no-gutters">
                <div className="col-sm py-2">
                    <div className={`card border-${className}`}>
                        <div className="card-body">
                            <div><strong className={`text-${className}`}>{event.toUpperCase()} </strong></div>
                            <hr />
                            <div className="float-right small">
                                <strong>Registered in Blockchain at : </strong>{moment(event_date).format('YYYY-MM-DD hh:mm:ss')}
                            </div>
                        </div>
                        <ul className="card-body small ps-4">
                            <TransactionDetails data={details} docuemnts={documents} />
                            <DocumentsDetails documents={documents} />
                        </ul>
                        <div className="card-body">
                            <div className="text-left small"><strong>Tx ID : </strong>{txid}</div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-1 text-center flex-column d-none d-sm-flex">
                    <div className="row h-50">
                        <div className={`col border-right border-${className}`}>&nbsp;</div>
                        <div className="col">&nbsp;</div>
                    </div>
                    <h5 className="m-2">
                        <span className={`badge badge-pill bg-light border border-${className}`}>
                            <img src={`./../icons/${event.split(' ').join('_')}.svg`} className={`text-${className}`} size={18} />
                        </span>
                    </h5>
                    <div className="row h-50">
                        <div className={`col border-right border-${className}`}>&nbsp;</div>
                        <div className="col"></div>
                    </div>
                </div>
                <div className="col-sm col-sm-timeline"> </div>
            </div>
        )
    }

    return (
        <>
            <Modal
                className={`max-w-full rounded-md p-6`}
                size="lg"
                show={!!quickFileContentDetails.data}
                onHide={() => setQuickFileContentDetails({ extension: '', data: '' })}
            >
                <Modal.Header
                    className="flex items-center justify-between"
                >
                    <Modal.Title>
                        <h6 className='mt-3'>File</h6>
                        {quickFileContentDetails.txId && <small className={'text-xs'}>{quickFileContentDetails.txId}</small>}
                    </Modal.Title>
                    <XSquare className='m-3' onClick={() => setQuickFileContentDetails({ extension: '', data: '' })} size={17} />

                </Modal.Header>
                <Modal.Body className="h-100">
                    {handleFileRender(quickFileContentDetails)}
                    {error && <div className="text-danger">Unable to load file</div>}
                </Modal.Body>
                <Modal.Footer>
                    <div onClick={() => setQuickFileContentDetails({ extension: '', data: '' })} className="btn btn-info">Close</div>
                </Modal.Footer>
            </Modal>
            <div className="container py-2 mt-4 mb-4 timeline">
                {
                    events.slice(0, isField ? events.length : events.length).map((event, index) => {
                        const { key, name, icon } = event;
                        const isEven = index % 2 === 0;
                        if (eventsData[key]) {
                            const { txid, data: { details, event_date, id, documents } } = eventsData[key];
                            return isEven
                                ? <EvenRowWithData documents={documents} event={name} details={details}
                                    event_date={event_date} txid={txid} className="success" />
                                : <OddRowWithData documents={documents} event={name} details={details}
                                    event_date={event_date} txid={txid} className="success" />
                        } else {
                            return isEven
                                ? <EvenRowEmpty event={name} className="default" />
                                : <OddRowEmpty event={name} className="default" />
                        }
                    })

                }
            </div>
        </>
    )

}

export default HistoryStory;