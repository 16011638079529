import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactJsonViewCompare from 'react-json-view-compare';
import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import { useNavigate } from "react-router-dom";

import StatCards from './../../components/Card/stat-cards';
import GoalAccomplished from './../../components/Shared/goal-accomplished';
import WidgetCard from './../../components/Card/widget-card';
import DefaultTable from './../../components/Table/default-table';
import QuickAccess from '../../components/Files/custom/quick-access';
import './theme.css';

import TransactionHistoryContainer from '../../components/Shared/TransactionHistoryContainer';
import HistoryStory from '../../components/HistoryStory';
import HistoryProgress from '../../components/HistoryProgress';

const SingleFieldDashboard = () => {

  const { state } = useLocation();
  const navigate = useNavigate();
  const { axios } = useContext(GeneralContext);

  const [data, setData] = useState(null);
  const [documents, setDocuments] = useState([])
  const [fieldData, setFieldData] = useState(null);
  const [fieldEvents, setFieldEvents] = useState(null);
  const [eventDocuments, setEventDocuments] = useState(null);
  const [fieldId, setFieldId] = useState(state ? state.fieldId : null);
  const [plotId, setPlotId] = useState(state ? state.plotId : null);
  const [fieldOwner, setFieldOwner] = useState(state ? state.owner : null);
  const [tokenCount, setTokenCount] = useState(120);
  const [tokenValue, setTokenValue] = useState((tokenCount * 22.50).toFixed(2));

  const getFieldEvents = async (fieldId) => {
    if(!fieldId || !plotId || !fieldOwner) return;

    let formatteduserError = {};
    try {
      const { error: userError, data: { data: userEvents } } = await axios.get(`/event/transactions-by-key/${fieldOwner}?page=1&items=1000`);
      formatteduserError = userEvents.reduce((acc, eventData) => {
        const { txid, timereceived, keys, data: { json: details } } = eventData;
        const { name } = details;
        return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
      }, {})

    } catch (error) {

    }

    let formattedFieldEvent = {};
    try {
      const { error: fieldError, data: { data: fieldEvent } } = await axios.get(`/event/transactions-by-key/${plotId}?page=1&items=1000`);
      formattedFieldEvent = fieldEvent.reduce((acc, eventData) => {
        const { txid, timereceived, keys, data: { json: details } } = eventData;
        const { name } = details;
        return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
      }, {})
    } catch (error) {

    }

    let formattedEventsData = {};
    try {
      const { error, data: { data: eventsData } } = await axios.get(`/event/transactions-by-key/${fieldId}?page=1&items=1000`);
      let imageList = 0;
      formattedEventsData = eventsData.reduce((acc, eventData, index) => {
        const { txid, timereceived, keys, data: { json: details } } = eventData;
        const { name } = details;
        if (acc[name]) {
          imageList = imageList + 1;
          return { ...acc, [`${name} ${imageList}`]: { txid, timereceived, data: { ...details } } };
        } else {
          return { ...acc, [name]: { txid, timereceived, data: { ...details } } };
        }
      }, {})
    } catch (error) {

    }

    let formattedEventDocuments = {};
    try {
      const documentSources = {
        "field": fieldId,
        "plot": plotId,
        "user": fieldOwner
      };
      for(const source in documentSources) {
        if(!documentSources[source]) continue;
        const { error, data: { data: documentsData } } = await axios.get(`/${source}-document-metadata/transactions-by-key/${documentSources[source]}?page=1&items=1000`);

        formattedEventDocuments = {
          ...formattedEventDocuments,
          ...documentsData.reduce((acc, documentData) => {
            const { txid, timereceived, keys, data: { json: details } } = documentData;
            const { document_id } = details;
            return { ...acc, [document_id]: { source, txid, timereceived, data: { ...details } } };
          }, {})
        };
      }
      setEventDocuments(formattedEventDocuments);
    } catch (error) {
      // Handle error
    }

    setFieldEvents({ ...formatteduserError, ...formattedFieldEvent, ...formattedEventsData, });

    const fieldRegisteredEvent = fieldEvents && fieldEvents["Field Registered"] ? fieldEvents["Field Registered"]?.data?.details?.data : null;
    setTokenCount(fieldRegisteredEvent ? fieldRegisteredEvent["Total carbon stock (t)"] : "120");
  }

  const rn = (max) => (Math.floor(Math.random() * max));

  const chartData = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];

  const chartData1 = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];

  const chartData2 = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];


  const analyticsStatData = [
    {
      id: '1',
      title: 'Plot Fields',
      metric: '112',
      info: 'Number of fields in this plot.',
      increased: true,
      decreased: false,
      // percentage: '+32.40',
      fill: '#015DE1',
      chart: chartData,
    },
    {
      id: '2',
      title: 'Tokens Issued',
      metric: tokenCount,
      info: 'Total tokens awarded for this field.',
      increased: false,
      decreased: true,
      // percentage: '-4.40',
      fill: '#B92E5D',
      chart: chartData1,
    },
    {
      id: '3',
      title: 'Total Token Value',
      metric: `£${tokenValue}`,
      info: 'Based upon a price of £22.50 per token.',
      increased: true,
      decreased: false,
      // percentage: '+31.40',
      fill: '#c70ca9',
      chart: chartData2,
    },
    {
      id: '4',
      title: 'CO2',
      metric: `${tokenCount} MT`,
      info: 'Metric Tonnes of CO2 emissions avoided.',
      increased: true,
      decreased: false,
      // percentage: '+32.40',
      fill: '#048848',
      chart: chartData2,
    }
  ];
  const getDocuments = async () => {
    const { error, data: { data } } = await axios.get(`/field-document-metadata/transactions-by-key/${fieldId}?page=1&items=10`);
    setDocuments(data);
  }

  const getFieldLocations = async () => {
    const { error, data: { data } } = await axios.get(`/location/transactions-by-key/${fieldId}?page=1&items=1000`);
    setData(data)
  }

  const getField = async () => {
    const { error, data: { data } } = await axios.get(`/field/transactions-by-key/${fieldId}?page=1&items=1000`);
    setFieldData(data.length ? data[0] : null);
    if(data.length && data[0]){
      const plotId = data[0].keys.reverse().find(key => key.startsWith('plot-')).split('-').pop();
      if(plotId) setPlotId(`plot-${plotId}`);
    }
  }

  const getPlot = async () => {
    if(!plotId) return;
    const { error, data: { data } } = await axios.get(`/plot/transactions-by-key/${plotId}?page=1&items=1000`);
    if(data.length && data[0]){
      const fieldOwner = data[0].keys.reverse().find(key => key.startsWith('owner-')).split('-').pop();
      if(fieldOwner) setFieldOwner(fieldOwner);
    }
  }

  useEffect(() => {
    if (fieldId === null) {
      navigate('/');
    } else {
      const fetchField = async () => {
        await getField();
      }
      fetchField().catch(console.error);

      const fetchPlot = async () => {
        await getPlot();
      }
      fetchPlot().catch(console.error);

      const fetchFieldDocuments = async () => {
        await getDocuments();
      }
      fetchFieldDocuments().catch(console.error);

      const fetchFieldLocationData = async () => {
        await getFieldLocations();
      }
      fetchFieldLocationData().catch(console.error);
    }
  }, [])

  useEffect(() => { }, [fieldData, data, documents, plotId, fieldOwner])

  useEffect(() => {
      const fetchFieldEventsData = async () => {
        await getFieldEvents(fieldId);
      }
      fetchFieldEventsData().catch(console.error);
  }, [plotId, fieldOwner, fieldId]);

  useEffect(() => {
        const fetchPlot = async () => {
          await getPlot();
        }
        fetchPlot().catch(console.error);
  }, [plotId]);

  return (
      <div id="field">
        <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
          <div className="mt-4 grid grid-cols-4 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
            <StatCards data={analyticsStatData}/>
          </div>
        </WidgetCard>
        <div className={"d-flex my-3 px-3 border-0 align-items-center"}>
          <h1>Field: {fieldId}</h1>
          {fieldData ? <small className={'ml-3'}>{fieldData?.txid}</small> : null}
        </div>
        <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3 mt-3'}>
          {documents.length ?
              <div><QuickAccess stream="field" withIcons={true} title="Field Documents" id={plotId} files={documents}/>
              </div> : <></>}
        </WidgetCard>
        <div className={"d-flex flex-col gap p-3"}>
          <div as="h3" className="text-lg font-semibold xl:text-xl">
            History
          </div>
          {fieldEvents && fieldData && plotId ?
              <div className={'ml-auto mr-auto'}><HistoryProgress events={fieldEvents}/></div> : <>Loading...</>}
        </div>
        <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3 mt-3'}>
          {fieldEvents && fieldData && fieldId ?
              <HistoryStory isField={false} events={fieldEvents} data={fieldData} fieldId={fieldId} documents={eventDocuments}/> : <></>}
        </WidgetCard>
      </div>
  )

}

export default SingleFieldDashboard;