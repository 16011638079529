import React, { useContext, useState, useEffect } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from "react-router-dom";
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Acquisition from './../../Chart/acquisition';
import DeviceSessions from './../../Chart/device-sessions';
import Forecast from './../../Chart/forecast';
import { DataContext } from './../../../context/DataContext';
import { GeneralContext } from './../../../context/GeneralContext';
import QuickAccess from '../../Files/custom/quick-access';

const tooltip = (
    <Tooltip id="tooltip">
        <div>This is a simplified calculation and assumes complete combustion of the soil.</div>
    </Tooltip>
);

const PlotMarkerPopup = ({ marker, markerDetails, setMarkerDetails }) => {

    const navigate = useNavigate();

    const { setActiveRoute } = useContext(DataContext);
    const { axios } = useContext(GeneralContext);

    const [fileContentDetails, setFileContentDetails] = useState([]);
    const { lat, lon, id, icon, stream, tokens, owner } = marker;

    const handleFileContent = async () => {
        try {
            const keys = `keys=${id},image-satellite`
            const { error, data: { data: documents } } = await axios.get(`/${stream}-document-metadata/transactions-by-keys?${keys}&page=1&items=10`);
            for await (const doc of documents) {
                const { data: { json: { name, hash, mimetype, extension } } } = doc;
                const { data: { error, data: content } } = await axios.get(`/document/${stream}/${id}/${name}`);
                if (!error) {
                    fileContentDetails.push({
                        extension,
                        data: extension === 'json' ? JSON.parse(Buffer.from(content, "base64").toString()) : `data:${mimetype};base64,${content}`
                    })
                    setFileContentDetails([...fileContentDetails]);
                }
            }
        } catch (err) {
            setFileContentDetails([]);
        }
    }
    const rnmin = (min, max) => (Math.random() * (max - min) + min);
    const rn = (max) => (Math.floor(Math.random() * max));

    const acquisition = [
        {
            day: 'Jan',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Feb',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Mar',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Apr',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'May',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Jun',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Jul',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Aug',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Sep',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Oct',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Nov',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
        {
            day: 'Dec',
            tokens: rnmin(10, 90),
            wells: rnmin(10, 30),
        },
    ];

    const deviceSessions = [
        {
            day: 'Mon',
            mobile: rn(90),
            desktop: rn(90),
            others: rn(90),
        },
        {
            day: 'Tue',
            mobile: rn(90),
            desktop: rn(90),
            others: rn(90),
        },
        {
            day: 'Thu',
            mobile: rn(90),
            desktop: rn(90),
            others: rn(90),
        },
        {
            day: 'Wed',
            mobile: rn(90),
            desktop: rn(90),
            others: rn(90),
        },
        {
            day: 'Fri',
            mobile: rn(90),
            desktop: rn(90),
            others: rn(90),
        },
        {
            day: 'Sun',
            mobile: rn(90),
            desktop: rn(90),
            others: rn(90),
        },
    ];

    return (markerDetails && markerDetails.id === id) &&
        <Popup className='w-50' id={id} key={id} latitude={lat} longitude={lon} onClose={() => setMarkerDetails(null)} closeOnClick={false}  >
            <div className='row pl-2 pr-1'>
                <div className='col-4'>
                    <div className='row pr-2'>
                        <div className='col-12'>
                            <strong className='font-head'>Plot Data</strong>
                            { markerDetails.txid ? <div className='text-left break-all small super-small-text'>{markerDetails.txid}</div> : <></>}
                            <hr />
                            <div className='d-flex flex-row'>
                                <div className='p-0'>Go to {icon} :</div>
                                <Nav.Link className="text- p-0 ms-1"
                                    onClick={() => {
                                        navigate(`/${icon}`, {
                                            state: {
                                                [`${icon}Id`]: id,
                                                latitude: lat,
                                                longitude: lon
                                            }
                                        });
                                        setActiveRoute(`${icon}`);
                                    }}>
                                    {id}
                                </Nav.Link>
                            </div>
                            <div className='d-flex flex-row'>
                                <div className='p-0'>Details :</div>
                                <Nav.Link className="text-info p-0 ms-1"
                                    onClick={() => {
                                        navigate(`/${icon}-data`, {
                                            state: {
                                                [`${icon}Id`]: id,
                                                owner
                                            }
                                        });
                                        setActiveRoute(`${icon}`);
                                    }}>
                                    {id}
                                </Nav.Link>
                            </div>
                            <hr />
                            <strong>Hedge Length (km) </strong>
                            <div>Hedge 0-4m : <span className="text-success">41.10</span></div>
                            <div>Hedge 4-7m : <span className="text-success">10.50</span></div>
                            <div>Hedge 7-10m : <span className="text-info">8.42</span></div>
                            <div>Hedge >10m : <span className="text-info">8.30</span></div>
                            <hr />
                            <strong>Canopy Area (ha) </strong>
                            <div>Single Trees : <span className="text-info">1.56</span></div>
                            <div>Deciduous Woodland : <span className="text-success">18.68</span></div>
                            <div>Coniferous Woodland : <span className="text-danger">0</span></div>
                            <div>Biomass : <span className="text-success">2.66</span></div>
                            <hr />
                            <strong>Estimated Quantity </strong>
                            <div>Carbon : <span className="text-success">53,699 (MT)</span></div>
                            <OverlayTrigger placement="right" overlay={tooltip}>
                                <div>CO2 emissions avoided* : <span className="text-success">196,896 (MT)</span></div>
                            </OverlayTrigger>
                            <hr />
                            <div><strong>Tokens : </strong><span
                                className="text-success">{tokens.toLocaleString()}</span></div>
                            <hr />
                            <div>
                                <strong>
                                    <Nav.Link className="text-info p-0 ms-1"
                                        onClick={(e) => {
                                            if (!!fileContentDetails.length) {
                                                setFileContentDetails([]);
                                            } else {
                                                handleFileContent()
                                            }
                                        }}>
                                        {`${fileContentDetails.length ? "Hide" : 'Show'} Satellite Images`}
                                    </Nav.Link>
                                </strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-8'>
                    <div className='row'>
                        {
                            fileContentDetails.length ? <div className='border-left-custom pl-2 pr-2 col-4'>
                                <div className='row mr-1 ml-1'>
                                    {
                                       fileContentDetails.map(({ data }, index) => {
                                            let bv = 0;
                                            let className = ''
                                            if (index) {
                                                bv = rnmin(0, 40).toFixed(0);
                                                className = bv < 10
                                                    ? ' border border-success'
                                                    : bv > 10 && bv < 30
                                                        ? ' border border-warning'
                                                        : ' border border-danger'
                                            }

                                            return index <= 1 ? (
                                                <div className={`col-12 ${className}`}>
                                                    {bv ? <div className='image-overlay text-center'><strong>Deviation</strong> : {bv / 100} %</div> : <></>}
                                                    <img className={`field-img-satellite p-1 ${className}`} alt="Render Images" width="100%" src={data} />
                                                    {!!index ? <></> : <hr />}
                                                </div>
                                            ) : <></>
                                        })
                                    }
                                </div>
                            </div>
                                : <></>
                        }
                        <div className={`border-left-custom pl-2 pr-2 ${fileContentDetails.length ? "col-8" : "col-12"}`}>
                            <Acquisition description={"112 Fields"} title="Canopy Area 2024" data={acquisition}
                                className="chart-fluid-2" />
                        </div>
                    </div>
                </div>
            </div>
        </Popup>
}

export default PlotMarkerPopup