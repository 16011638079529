import { useEffect, useState, useContext } from 'react';
import { GeneralContext } from '../../context/GeneralContext';
import { useLocation } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactJsonViewCompare from 'react-json-view-compare';
import { useNavigate } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from "moment";
import StatCards from './../../components/Card/stat-cards';
import GoalAccomplished from './../../components/Shared/goal-accomplished';
import WidgetCard from './../../components/Card/widget-card';
import DefaultTable from './../../components/Table/default-table';
import QuickAccess from '../../components/Files/custom/quick-access';
import './theme.css';
import {DataContext} from "../../context/DataContext";

const FieldDashboard = () => {

  const { env } = useContext(GeneralContext);
  const { setActiveRoute } = useContext(DataContext);
  const { axios } = useContext(GeneralContext);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [fieldData, setFieldData] = useState(null);
  const [fieldsLocationData, setFieldsLocationData] = useState(null);
  const [fullFieldData, setFullFieldData] = useState([]);
  const [assetsData, setAssetsData] = useState([]);

  const rn = (max) => (Math.floor(Math.random() * max));

  const chartData = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];

  const chartData1 = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];

  const chartData2 = [
    {
      day: 'Sunday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Monday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Tuesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Wednesday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Thursday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Friday',
      sale: rn(4000),
      cost: rn(4000),
    },
    {
      day: 'Saturday',
      sale: rn(4000),
      cost: rn(4000),
    },
  ];

  const analyticsStatData = [
    {
      id: '1',
      title: 'Fields',
      metric: '112',
      info: 'Number of fields in the system.',
      increased: true,
      decreased: false,
      // percentage: '+32.40',
      fill: '#015DE1',
      chart: chartData,
    },
    {
      id: '2',
      title: 'Above Ground Biomass',
      metric: '3,127.66',
      info: 'Total Above Ground Biomass (MT).',
      increased: false,
      decreased: true,
      // percentage: '-4.40',
      fill: '#B92E5D',
      chart: chartData1,
    },
    {
      id: '3',
      title: 'Above Ground Carbon',
      metric: `1,563.80`,
      info: 'Total Above Ground Carbon (MT).',
      increased: true,
      decreased: false,
      // percentage: '+31.40',
      fill: '#c70ca9',
      chart: chartData2,
    },
    {
      id: '4',
      title: 'CO2e',
      metric: '142 MT',
      info: 'Metric Tonnes of CO2e emissions avoided.',
      increased: true,
      decreased: false,
      // percentage: '+32.40',
      fill: '#048848',
      chart: chartData2,
    }
  ];

  const columns = [
    {
        dataField: 'data',
        text: 'Field',
        sort: true,
        formatter: (data, index) => {
            return <div className={'d-flex align-items-center'}>
                <img className={'w-20 h-20 border-2 border-gray-200 rounded-circle'}
                    src={`https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${data.location.lon},${data.location.lat},10,0/120x120?access_token=${env.maps}`}
                     alt={`Satellite image for farm-field-${data?.field.id}`} />
                <div className={'d-flex flex-column ml-4'}>
                    <Nav.Link className="text-info p-0"
                              title={`View field-${data?.field.id} details`}
                              onClick={() => {
                                navigate(`/field-data`, { state: { [`fieldId`]: `plot-field-${data?.field.id}` } });
                                setActiveRoute(`field`);
                              }}>
                        {`field-${data?.field.id}`}
                    </Nav.Link>
                    <small>{data?.field.txid}</small>
                </div>
            </div>;
        },
    },
    {
        dataField: 'data',
        text: 'Data',
        sort: true,
        formatter: (data, index) => {
            return <div className={'d-flex flex-column gap'}>
                <span>{data?.field.description ?? null}</span>
                <span><strong>Tokens:</strong> {data?.assets?.reduce((totalTokens, asset) => totalTokens + asset.issueqty, 0)}</span>
            </div>;
        }
    },
    {
        dataField: 'data.field.timereceived',
        text: 'Created At',
        sort: true,
        filter: dateFilter(),
    },
  ];

  const getFieldLocations = async () => {
    try {
      const { error, data: { data } } = await axios.get(`/location/transactions-by-key/field?page=1&items=1000`);
      if (!error) {
        setFieldsLocationData(data);
      }
    } catch (error) {

    }
  }

  const getFields = async () => {
    try {
      const { error, data: { data } } = await axios.get('/field?page=1&items=1000');
      if (!error) {
        const uniqueFieldData = data.reverse().reduce((fields, field) => {
          const { keys, data: { json: { id } } } = field;
          if (!fields.some((w) => w.data.json.id === id)) {
            fields.push({ ...field, timereceived: moment(field.timereceived * 1000).format('YYYY-MM-DD') });
          }
          return fields;
        }, [])
        setFieldData(uniqueFieldData)
      }
    } catch (error) {

    }
  }

  const getAssets = async () => {
      try {
          const { error, data: { data } } = await axios.get('/nfts?page=1&items=1000');
          if (!error) {
              const uniqueAssetsData = data.assets.reverse().reduce((assets, asset) => {
                  if (!assets.some((f) => f.name === asset.name)) {
                      assets.push({ ...asset, timereceived: moment(asset.timereceived * 1000).format('YYYY-MM-DD') });
                  }
                  return assets;
              }, [])
              setAssetsData(uniqueAssetsData);
          }
      } catch (error) {

      }
  }

  const mergeFieldData = async () => {
        if (fieldData && fieldsLocationData) {
            const data = fieldData.map((field) => {
                const {data} = field;
                const location = fieldsLocationData.find(({data: {json: {id: fid}}}) => (fid === `plot-field-${data.json.id}`));
                return {
                    data: {
                        field: {
                            ...data.json,
                            txid: field.txid,
                            timereceived: field.timereceived
                        },
                        assets: (assetsData ? assetsData.filter(({details: {field_id}}) => (field_id === data.json.id)) : []),
                        location: {
                            lat: location?.data.json?.lat ?? null,
                            lon: location?.data.json?.lon ?? null
                        }
                    }
                };
            });
            setFullFieldData(data);
        } else {
            setFullFieldData([]);
        }
    }


  useEffect(() => {
      getFieldLocations().catch(console.error);
      getFields().catch(console.error);
      getAssets().catch(console.error);
  }, [])

  useEffect(() => {
      mergeFieldData().catch(console.error);
  }, [fieldData, fieldsLocationData, assetsData])

  useEffect(() => {
  }, [fieldData, fieldsLocationData, assetsData])

  return (
      <div id="field">
          <WidgetCard className={'grid grid-cols-1 m-0 p-0 border-0 ml-3 mr-3'}>
              <div className="mt-4 grid grid-cols-4 justify-around gap-6 @sm:py-3 @md:mt-3 @xl:mt-4 @7xl:gap-8">
                  <StatCards data={analyticsStatData}/>
              </div>
          </WidgetCard>
          <div className={"d-flex mt-3 mb-n2 pl-4 border-0"}>
              <h1>Fields</h1>
          </div>
          <WidgetCard className={'mt-2 p-0 border-0'}>
              <div className="grid grid-cols-1">
                  {
                      (fullFieldData && fullFieldData.length) ?
                          <WidgetCard className={'m-3'}>
                              <BootstrapTable
                                  fluid
                                  bootstrap4
                                  keyField='timestamp'
                                  columns={columns}
                                  data={fullFieldData}
                                  filter={filterFactory()}
                                  filterPosition="top"
                                  pagination={paginationFactory()}
                              />
                          </WidgetCard> : <></>
                  }
              </div>
          </WidgetCard>
      </div>
  )

}

export default FieldDashboard;