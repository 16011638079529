import WidgetCard from './../Card/widget-card';

import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';


const addSpacesToCamelCase = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export const CustomTooltip = ({
    label,
    prefix,
    postfix,
    payload,
}) => {

    return (
        <div
            className={'tooltip-width overflow-hidden rounded-md border border-gray-300 bg-gray-0 shadow-2xl dark:bg-gray-100'}
        >
            <div className="label mb-0.5 block bg-gray-100 p-2 px-2.5 text-center font-lexend text-xs font-semibold capitalize text-gray-600 dark:bg-gray-200/60 dark:text-gray-700">
                {label}
            </div>
            <div className="px-3 py-1.5 text-xs">
                {payload?.map((item, index) => {
                    return < div
                        key={item.dataKey + index}
                        className="chart-tooltip-item flex items-center py-1.5"
                    >
                        <span
                            className="me-1.5 h-2 w-2 rounded-full"
                        />
                        <div>
                            <div as="span" className="capitalize">
                                {addSpacesToCamelCase(item.name)} : <strong>{item.value}</strong>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div >
    )
}

const Acquisition = ({ description, title, data, className }) => {

    return (
        <WidgetCard
            title={title}
            titleClassName="font-normal sm:text-sm text-gray-500 mb-2.5 font-inter text-left"
            className={className}
            description={
                <div as="h2" className="me-2 text-left">
                    {description}
                </div>
            }
        >
            <ResponsiveContainer className={className} width="100%" height="100%">
                <AreaChart
                    data={data}
                    margin={{
                        left: -30,
                    }}
                >
                    <XAxis dataKey="day" tickLine={false} />
                    <YAxis tickLine={false} scale={'linear'} />
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                        type="natural"
                        dataKey="tokens"
                        name="fields"
                        stackId="acquisitionStackID"
                        stroke="#f98608"
                        fill="#f98608"
                        strokeWidth={1.5}
                        fillOpacity={0.7}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </WidgetCard>
    );
}

export default Acquisition
