import {useContext, useEffect, useState} from 'react';
import {GeneralContext} from '../../context/GeneralContext';
import {DataContext} from '../../context/DataContext';
import MapView from './../../components/Map/index';
import {useLocation} from 'react-router-dom';
import {defaultEventList, formatAreaCollection} from './../../utils';
import FieldMarkerPopup from './../../components/Map/elements/field-popup'
import MapMarker from './../../components/Map/elements/marker'
import Acquisition from './../../components/Chart/acquisition';
import LoadingSpinner from './../../assets/icons/LoadingSpinner.svg';

import './theme.css';

const Plot = () => {

    const {state} = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    const {axios} = useContext(GeneralContext);
    const {mapDefaultLatitude, mapDefaultLongitude, mapDefaultStyle} = useContext(DataContext);

    const [mapElements, setMapElements] = useState({markers: [], areas: null});
    const [viewport, setViewport] = useState(
        {
            latitude: state ? state.latitude : mapDefaultLatitude,
            longitude: state ? state.longitude : mapDefaultLongitude,
            zoom: 15
        }
    );
    const [markerDetails, setMarkerDetails] = useState(null);
    const [fieldEvents, setfieldEvents] = useState(null);
    const [fieldSatelliteImages, setFieldSatelliteImages] = useState([]);
    const [plotId, setPlotId] = useState(state ? state.plotId : 'plot');
    const [fieldOwner, setFieldOwner] = useState(state ? state.owner : null);


    const getPlot = async () => {
        if (!plotId) return;
        const {error, data: {data}} = await axios.get(`/plot/transactions-by-key/${plotId}?page=1&items=1000`);
        if (data.length && data[0]) {
            const fieldOwner = data[0].keys.reverse().find(key => key.startsWith('owner-')).split('-').pop();
            if (fieldOwner) {
                setFieldOwner(fieldOwner);
            } else {
                console.log("No owner data found");
            }
        } else {
            console.log("No plot data found");
        }
    }

    const getPlotLocations = async () => {
        setIsLoading(true);
        await getPlot();
        const {
            error: plotListingError,
            data: {data: plotLocationData}
        } = await axios.get(`/plot/transactions-by-key/${plotId || "plot"}?page=1&items=1000`);
        const uniquePlotData = plotLocationData.reverse().reduce((plots, plot) => {
            const {txid, keys, data: {json: {id, tokens}}} = plot;
            if (plots[id] !== id && !plots[id]) {
                return {...plots, [id]: {txid, ...plot}}
            }
            return uniquePlotData;
        }, {})

        const fieldSatelliteImagesResult = await getFieldSatelliteImages(plotId);

        const {data: {data: fieldDataLocation}} = await axios.get(`/location/transactions-by-key/${plotId || 'field'}?page=1&items=1000`);

        const fieldList = fieldDataLocation.filter(({keys}) => keys.includes('field')).map(({data: {json: {id}}}) => id);

        const events = {};
        for (const field of fieldList) {
            events[field] = await getFieldEventsForField(field);
        }

        const completedEventIndex = defaultEventList.filter((e) => e.type === "field").findIndex((e) => e.name === "Tokens Issued");

        const markers = fieldDataLocation
            .map(({txid, keys, data: {json: {lat, lon, id, area}}}) => {
                console.log(`Mapping marker ${txid}`)
                console.log(`Owner: ${fieldOwner}`)
                if (keys.includes('field')) {
                    const fid = id.split('-').pop();
                    return {
                        plotId: plotId,
                        area,
                        lat,
                        lon,
                        id,
                        type: 'marker',
                        icon: "field",
                        hasSatelliteImages: fieldSatelliteImagesResult.find(field => field.id === id),
                        tokens: uniquePlotData[fid]?.tokens || 0,
                        className: `field ${events[id]?.length > 3 ? ((events[id]?.length >= completedEventIndex) ? 'field-status-active' : 'field-status-verified') : 'field-status-created'}`,
                        stream: "field",
                        owner: fieldOwner,
                        txid
                    }
                } else {
                    return false;
                }
            })
            .filter(Boolean);

        const {data: {data: fieldData}} = await axios.get(`/location/transactions-by-key/${plotId}?page=1&items=1000`);
        const fields = fieldData
            .map(({keys, data: {json: {lat, lon, id, area}}}) => (keys.includes('field') ? {
                area,
                lat,
                lon,
                id,
                type: 'marker',
                icon: "field",
                className: `field ${uniquePlotData[id]}`,
                stream: "plot"
            } : false))
            .filter(Boolean);
        setMapElements({markers, areas: {"type": "FeatureCollection", features: formatAreaCollection(fields)}});
        setIsLoading(false);
    }

    useEffect(async () => {

        const fetchPlotLocationData = async () => {
            await getPlotLocations();
        }
        fetchPlotLocationData().catch(console.error);
    }, [plotId, fieldOwner]);

    const rn = (max) => (Math.floor(Math.random() * max));

    const getFieldSatelliteImages = async (plotId) => {
        let fieldSatelliteImages = {};
        try {
            const {
                error,
                data: {data: images}
            } = await axios.get(`/field-document-metadata/transactions-by-keys/?keys=${plotId},image-satellite&page=1&items=1000`);
            fieldSatelliteImages = images.map((image) => {
                const {data: {json}} = image;
                return json;
            }, {});
            setFieldSatelliteImages(fieldSatelliteImages);
            return fieldSatelliteImages;
        } catch (error) {
            console.error(error);
            setFieldSatelliteImages([]);
        }
        return [];
    }

    const getFieldEvents = async (marker) => {

        console.log(marker);
        if (marker) {
            const {id, owner} = marker;

            let formattedUserEvents = {};
            try {
                const {
                    error: userError,
                    data: {data: userEvents}
                } = await axios.get(`/event/transactions-by-key/${owner}?page=1&items=1000`);
                formattedUserEvents = userEvents.reduce((acc, eventData) => {
                    const {txid, timereceived, keys, data: {json: details}} = eventData;
                    const {name} = details;
                    return {...acc, [name]: {txid, timereceived, data: {...details}}};
                }, {})

            } catch (error) {

            }

            let formattedPlotEvent = {};
            try {
                const {
                    error: plotError,
                    data: {data: plotData}
                } = await axios.get(`/event/transactions-by-key/${plotId}?page=1&items=1000`);
                formattedPlotEvent = plotData.reduce((acc, eventData) => {
                    const {txid, timereceived, keys, data: {json: details}} = eventData;
                    const {name} = details;
                    return {...acc, [name]: {txid, timereceived, data: {...details}}};
                }, {})
            } catch (error) {

            }
            let formattedEventsData = {};
            try {
                const {
                    error,
                    data: {data: eventsData}
                } = await axios.get(`/event/transactions-by-key/${id}?page=1&items=1000`);
                let imageList = 0;
                formattedEventsData = eventsData.reduce((acc, eventData, index) => {
                    const {txid, timereceived, keys, data: {json: details}} = eventData;
                    const {name} = details;
                    if (acc[name]) {
                        imageList = imageList + 1;
                        return {...acc, [`${name} ${imageList}`]: {txid, timereceived, data: {...details}}};
                    } else {
                        return {...acc, [name]: {txid, timereceived, data: {...details}}};
                    }
                }, {})
            } catch (error) {

            }

            setfieldEvents({...formattedUserEvents, ...formattedPlotEvent, ...formattedEventsData,});
        }
    }

    const getFieldEventsForField = async (id) => {

        let formattedEventsData = [];
        try {
            const {
                error,
                data: {data: eventsData}
            } = await axios.get(`/event/transactions-by-key/${id}?page=1&items=1000`);
            formattedEventsData = eventsData.map((eventData) => {
                const {data: {json}} = eventData;
                return json;
            }, {})
        } catch (error) {
            console.error(error);
        }
        return formattedEventsData;
    }

    useEffect(() => {
    }, [mapElements, fieldEvents, fieldSatelliteImages])

    const acquisition = [
        {
            day: 'Jan',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Feb',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Mar',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Apr',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'May',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Jun',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Jul',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Aug',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Sep',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Oct',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Nov',
            tokens: rn(90),
            wells: rn(30),
        },
        {
            day: 'Dec',
            tokens: rn(90),
            wells: rn(30),
        },
    ];


    const MarkerList = () => {
        return mapElements.markers ?
            mapElements.markers.map((marker, index) => {
                return (
                    <div key={`markers-list-${index}`}>
                        <FieldMarkerPopup
                            fieldEvents={fieldEvents}
                            wellSatelliteImages={fieldSatelliteImages}
                            marker={marker}
                            markerDetails={markerDetails}
                            setMarkerDetails={setMarkerDetails}
                        />
                        <MapMarker
                            marker={marker}
                            setViewport={setViewport}
                            markerDetails={markerDetails}
                            setMarkerDetails={setMarkerDetails}
                            getWellEvents={getFieldEvents}
                        />
                    </div>
                );
            }) : <></>;
    }

    return (
        <>
            {isLoading && <div className="loading-overlay text-white">
                <img src="/icons/LoadingSpinner.svg" alt="Loading..." className="spinner-icon text-white"/>
                &nbsp;Loading...
            </div>}
            <MapView
                viewport={viewport}
                setViewport={setViewport}
                mapElements={mapElements}
                mapType={mapDefaultStyle}
            >
                <MarkerList/>
            </MapView>
            <div className="pabr text-center">
                <div className='row pt-2 pb-2 pl-4 pr-4'>
                    <div className='col-12 m-2'>
                        <Acquisition description={"53,699 MT Co2"} title="Total Proven Reserves"
                                     data={acquisition} className="chart-overlay"/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Plot;